import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faEllipsis,
  faBarsStaggered,
  faPaperPlane,
  faCross,
} from "@fortawesome/free-solid-svg-icons";
import Expertise from "./app/components/expertiseSection";
import MobileSidebarSection from "./app/components/mobileSidebar";
import SidebarSection from "./app/components/sidebar";
import {
  botCommunication,
  kycTotlr,
  saveToChatModel,
  saveToChatHistory,
} from "./app/api/api";

function App() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [openSideMenu, setOpenSideMenu] = useState(false);

  // Chatbot parameters
  const [firstMessage, setFirstMessage] = useState("");
  const [secondMessage, setSecondMessage] = useState("");
  const [chatStatus, setChatStatus] = useState(0);
  const [nameStatus, setNameStatus] = useState(0);
  const [saveStatus, setSaveStatus] = useState(0);
  const [chatSaveStatus, setChatSaveStatus] = useState(0);
  // const [contactMode, setContactMode] = useState(0);
  const [kycStatus, setKycStatus] = useState(0);
  // const [chatIdStatus, setChatIdStatus] = useState(0);
  const [chatId, setChatId] = useState("");
  const [chatModelId, setChatModelId] = useState("");
  const [chatName, setChatName] = useState("");
  const [chatEmail, setChatEmail] = useState("");
  const [chatPhone, setChatPhone] = useState("");
  const [commMode, setCommMode] = useState("");
  const [showButton, setShowButton] = useState(false);

  const scrollableRef = useRef(null);
  // useEffect(() => {
  //   if (
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
  //       navigator.userAgent
  //     )
  //   ) {
  //     setDeviceType("Mobile");
  //   } else {
  //     setDeviceType("Desktop");
  //   }

  //   console.log("Device", deviceType);
  // }, []);

  useEffect(() => {
    const mediaQueryMobile = window.matchMedia("(max-width: 767px)");
    const mediaQueryTablet = window.matchMedia(
      "(min-width: 768px) and (max-width: 1024px)"
    );
    const mediaQueryDesktop = window.matchMedia("(min-width: 1025px)");

    const updateDeviceType = () => {
      if (mediaQueryMobile.matches) {
        setDeviceType("Mobile");
      } else if (mediaQueryTablet.matches) {
        setDeviceType("Tablet");
      } else if (mediaQueryDesktop.matches) {
        setDeviceType("Desktop");
      }
    };

    updateDeviceType(); // Initial check
    mediaQueryMobile.addEventListener("change", updateDeviceType);
    mediaQueryTablet.addEventListener("change", updateDeviceType);
    mediaQueryDesktop.addEventListener("change", updateDeviceType);

    return () => {
      mediaQueryMobile.removeEventListener("change", updateDeviceType);
      mediaQueryTablet.removeEventListener("change", updateDeviceType);
      mediaQueryDesktop.removeEventListener("change", updateDeviceType);
    };
  }, []);

  useEffect(() => {
    console.log("user name", chatName);
  }, [chatName]);
  useEffect(() => {
    console.log("chatSaveStatus....", chatSaveStatus);
  }, [chatSaveStatus]);
  useEffect(() => {
    console.log("user email", chatEmail);
  }, [chatEmail]);
  useEffect(() => {
    console.log("user phone", chatPhone);
  }, [chatPhone]);
  useEffect(() => {
    console.log("chat id", chatId);
  }, [chatId]);
  useEffect(() => {
    console.log("chat model id", chatModelId);
  }, [chatModelId]);

  useEffect(() => {
    if (firstMessage !== "") {
      if (chatSaveStatus === 0) {
        const userData = {
          chatName: chatName,
          chatEmail: chatEmail,
          chatPhone: chatPhone,
          message: firstMessage,
        };
        saveChatModel(userData);
      } else {
        console.log("already in chatmodel");
      }
    }

    console.log("stored firstMessage", firstMessage);
  }, [firstMessage]);

  // useEffect(()=>{
  //   if(commMode!=='')
  //   {
  //     ChatWithBot('callemailwhatsapp',commMode)
  //     if(kycStatus===0){
  //       const userData = {
  //         'message':`${firstMessage}, ${secondMessage} (prefered mode of communication - ${commMode})` ,
  //         'chatName':chatName,
  //         'chatEmail':chatEmail,
  //         'chatPhone':chatPhone,
  //     };
  //       saveToTlr(userData)
  //     }
  //     else{
  //       console.log('already register in tlr')
  //     }

  //   }
  //   console.log('stored comm mode',commMode)
  // },[commMode])

  const saveChatsToHistory = async (userHistData) => {
    let saveHist_status = await saveToChatHistory(userHistData);
    if (saveHist_status === 201) {
      console.log("saved chat history");
    } else {
      console.log("Error in saving chat history");
    }
  };

  const saveToTlr = async (userData) => {
    // const saveToTlr = ()=>{
    console.log("inside savetlr.");
    let kyc_status = await kycTotlr(userData);
    console.log("inside savetlr.", kyc_status);
    if (kyc_status === 200) {
      setKycStatus(1);
      console.log("kyc sucess");
    } else {
      console.log("kyc not success");
    }
  };

  const saveChatModel = async (userData) => {
    console.log("inside save model", userData);
    let sav_status = await saveToChatModel(userData);
    console.log("sav_status.chatId..", sav_status.data.chat_id);
    setChatId(sav_status.data.chat_id);
    setChatModelId(sav_status.data.chat_modelid);
    console.log("sav_status.data.chat_modelid..", sav_status.data.chat_modelid);
    console.log("save model status.....", sav_status);

    if (sav_status.status === "1") {
      setChatSaveStatus(1);
      console.log("save model sucess");
      const questionArray = [
        "Thank you..!please enter your email",
        "please enter your phone digits",
        "Thank you for sharing your details; How can we assist you ?",
      ];
      const answerArray = [chatName, chatEmail, chatPhone];
      const userHistData = {
        question: questionArray,
        answer: answerArray,
        chatid: sav_status.data.chat_id,
        modelid: sav_status.data.chat_modelid,
        infrm_status: "basic",
      };
      let saveHist_status = await saveToChatHistory(userHistData);
      if (saveHist_status === 201) {
        console.log("saved chat history");
      } else {
        console.log("Error in saving chat history");
      }
    } else {
      console.log("save model not success");
    }
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  };

  const emailExtraction = (emailId) => {
    // Define the regular expression pattern for email addresses
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Extract email addresses using the regular expression
    const emailAddresses = emailId.match(emailPattern) || [];

    // Extract the first email address found
    const botResponse = emailAddresses.length > 0 ? emailAddresses[0] : "";

    console.log("bot response:", botResponse);
    return botResponse;
  };
  const phoneExtraction = (phone) => {
    // Define the regular expression pattern for extracting phone numbers
    const phonePattern = /\+?\d{1,4}[\s()-]?\d{6,}/g;

    // Find all matches in the text using the regular expression pattern
    const matches = phone.match(phonePattern) || [];

    console.log("Phone numbers in matches:", matches);

    // Extract the first phone number found
    const botResponse = matches.length > 0 ? matches[0] : "";

    return botResponse;
  };

  const handleInputChange = (e) => {
    console.log("eeeee", e);
    setInputValue(e.target.value);
  };

  const scrollToTop = () => {
    console.log("messages.length()", messages.length - 1);
    const element = document.getElementById(
      `messageData${messages.length - 1}`
    );
    if (scrollableRef.current && element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    // var element = document.getElementById(`messageData${messages.length-1}`);
    // element?.scrollIntoView();
  };
  const openSideBarMenu = () => {
    setOpenSideMenu(!openSideMenu);
  };

  const ChatWithBot = async (user_Message, messgae = "") => {
    console.log("user_Message first time...", user_Message);
    const currentTime = getCurrentTime();

    if (messgae === "firstmsg") {
      // ChatWithBot('callemailwhatsapp',commMode)
      if (kycStatus === 0) {
        const userData = {
          // 'message':`${firstMessage}, ${secondMessage} (prefered mode of communication - ${commMode})` ,
          message: user_Message,
          chatName: chatName,
          chatEmail: chatEmail,
          chatPhone: chatPhone,
        };
        saveToTlr(userData);
      } else {
        console.log("already register in tlr");
      }
      // user_Message= secondMessage
      // console.log('inside',secondMessage)
      const userMessage = {
        content: user_Message,
        sender: "user",
        time: currentTime,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      // Clear input field
      //  setIsTyping(true);
    } else {
      const userMessage = {
        content: user_Message,
        sender: "user",
        time: currentTime,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      // Clear input field
      //  setIsTyping(true);
    }

    let bot_responses = await botCommunication(user_Message);
    console.log("inside...", bot_responses);
    let botResponse;
    if (bot_responses !== "") {
      const phrasesToCheck = [
        "consultation",
        "appointment?",
        "consultation with our legal team?",
        "consultation with them?",
        "consultation with us?",
      ];
      // const showButtons = endsWithAny(botResponse, phrasesToCheck);

      //  let show = phrasesToCheck.some(phrase => bot_responses.endsWith(phrase));
      //  console.log('showButtons.............',show)
      //  if (show) {
      //    console.log('Matching phrase detected:', botResponse);

      //    botResponse = {
      //      content: bot_responses,
      //      sender: "bot",
      //      time: currentTime,
      //      buttons: (
      //        <div className="flex items-center pt-5">
      //          <button onClick={(evt) => callCommunication(1)} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">
      //            Book Appointment
      //          </button>
      //          {/* <button onClick={(evt) => callCommunication(2)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
      //            No
      //          </button> */}
      //          {/* <button onClick={(evt) => callCommunication(3)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      //            Email
      //          </button> */}
      //        </div>
      //      )
      //    };

      //   } else {
      //    botResponse = { content: bot_responses, sender: "bot", time: currentTime };
      //       console.log("The response does not end with any of the specified phrases.");
      //   }

      //  botResponse = { content: bot_responses, sender: "bot", time: currentTime };

      // let botResponse;
      // if (user_Message.toLowerCase().includes("hello")) {
      //   botResponse = { content: "Hello there!", sender: "bot", time: currentTime };
      // } else if (user_Message.toLowerCase().includes("how are you")) {
      //   botResponse = { content: "I'm fine, thank you!", sender: "bot", time: currentTime };
      // } else {
      //   botResponse = { content: "I'm sorry, I don't understand.", sender: "bot", time: currentTime };
      // }
      botResponse = {
        content: bot_responses,
        sender: "bot",
        time: currentTime,
      };

      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setIsTyping(false);
    } else {
      bot_responses = "Please consult us for more details ";
      botResponse = {
        content: bot_responses,
        sender: "bot",
        time: currentTime,
      };

      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setIsTyping(false);
    }

    console.log("user_Message before saving hist....", user_Message);
    console.log("bot response before saving hist....", bot_responses);
    console.log("before savechat....", chatId, chatModelId);

    const userHistData = {
      question: bot_responses,
      answer: user_Message,
      chatid: chatId,
      modelid: chatModelId,
      infrm_status: "",
    };
    saveChatsToHistory(userHistData);
  };

  const handleSendMessage = async () => {
    var user_Message = inputValue;
    console.log("user_Message.....", user_Message);
    let botResponse;
    if (user_Message.trim() !== "") {
      // var user_Message = inputValue;
      // console.log('user_Message.....',user_Message)
      const currentTime = getCurrentTime();

      if (chatStatus === 0) {
        // alert('userMessage..'+user_Message)

        const userMessage = {
          content: user_Message,
          sender: "user",
          time: currentTime,
        };

        // Add user message to messages state
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Clear input field
        setIsTyping(true);
        setTimeout(() => {
          const currentTime = getCurrentTime();

          // Add bot response to messages state
          botResponse = {
            content: "May I please have your name ?",
            sender: "bot",
            time: currentTime,
          };
          // botResponse = { content: "May I please have your name ?", sender: "bot", time: currentTime,redirect:'<a href="https://thelawreporters.com/" target="#blank"><p>test here</p></a>' };
          setMessages((prevMessages) => [...prevMessages, botResponse]);
          setIsTyping(false);
        }, 1300);

        setChatStatus(1);
        // alert('ChatStatus..'+chatStatus)
      } else if (chatStatus === 1) {
        // This condition is to process the name from user input
        let name = user_Message.trim();

        // Check if the message contains a name phrase
        const namePattern = /(?:my name is|name is|call me)\s+(\w+)/i;
        const match = namePattern.exec(name);

        if (match) {
          // If a pattern is found, extract the name
          name = match[1];
        } else {
          // If no name phrase is found, treat the input as a direct name response
          const nameWords = name.split(/\s+/); // Split by whitespace
          const numberOfWords = nameWords.length;
          // If it looks like a single or two-word name without 'name' in it, prepend 'name is'
          const containsNameWord = nameWords.some(
            (word) => word.toLowerCase() === "name"
          );
          if (!containsNameWord && numberOfWords <= 2) {
            user_Message = "name is " + name;
          }
        }
        console.log("userName.....", user_Message);
        setChatName(name);
        const userMessage = {
          content: user_Message,
          sender: "user",
          time: currentTime,
        };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Clear input field
        setIsTyping(true);
        setTimeout(() => {
          const currentTime = getCurrentTime();
          botResponse = {
            content: "please enter your email id ",
            sender: "bot",
            time: currentTime,
          };
          setMessages((prevMessages) => [...prevMessages, botResponse]);
          setIsTyping(false);
        }, 1300);

        setChatStatus(2);
      } else if (chatStatus === 2) {
        // this condition is to processing email from user input
        let message;
        let bot_responses = emailExtraction(user_Message);
        console.log("email bot_responses...", bot_responses);
        if (bot_responses !== "") {
          message = "please enter your phone digits";
          setChatEmail(bot_responses);
          setChatStatus(3);
        } else {
          message = "invalid email,please enter email";
        }

        const userMessage = {
          content: user_Message,
          sender: "user",
          time: currentTime,
        };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Clear input field
        setIsTyping(true);
        setTimeout(() => {
          const currentTime = getCurrentTime();
          botResponse = { content: message, sender: "bot", time: currentTime };
          setMessages((prevMessages) => [...prevMessages, botResponse]);
          setIsTyping(false);
        }, 1300);
      } else if (chatStatus === 3) {
        // this condition is to processing phone from user input
        let message;
        let bot_responses = phoneExtraction(user_Message);
        console.log("phone...", bot_responses);
        if (bot_responses !== "") {
          // message=`Thank you ${chatName},for sharing your details; How can we assist you ?`
          message =
            "Thank you for sharing your details; How can we assist you ?";
          setChatPhone(bot_responses);
          setChatStatus(4);
          setSaveStatus(1);
        } else {
          message = "invalid contact number,please enter again";
        }

        const userMessage = {
          content: user_Message,
          sender: "user",
          time: currentTime,
        };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Clear input field
        setIsTyping(true);
        setTimeout(() => {
          const currentTime = getCurrentTime();
          botResponse = { content: message, sender: "bot", time: currentTime };
          setMessages((prevMessages) => [...prevMessages, botResponse]);
          setIsTyping(false);
        }, 1300);
      } else if (chatStatus === 4) {
        if (saveStatus === 1) {
          setFirstMessage(user_Message);
          setSaveStatus(2);

          // await ChatWithBot(user_Message)
          await ChatWithBot(user_Message, "firstmsg");
        } else if (saveStatus === 2) {
          setSecondMessage(user_Message);
          setSaveStatus(3);
          ChatWithBot(user_Message, "chitchat");
        } else {
          //save first message
          ChatWithBot(user_Message);
        }
      }

      // else if(saveStatus===2){
      //   // save second message
      //   setSecondMessage(user_Message)
      //   user_Message='chitchat'
      //   ChatWithBot(user_Message)
      // }
      else {
        // to save first message
        // const userMessage = { content: user_Message, sender: "user", time: currentTime };
        // // Add user message to messages state
        // setMessages(prevMessages => [...prevMessages, userMessage]);
        // setInputValue(""); // Clear input field
        // setIsTyping(true);
        // setTimeout(async () => {
        //   const currentTime = getCurrentTime();
        //   let botResponse;
        // ChatWithBot(user_Message)
        // let bot_responses = await botCommunication(user_Message)
        // // console.log('bot response',bot_res)
        // // let buttonsData = bot_res.buttons_data;
        // // console.log('buttonsData..'+ buttonsData)
        // // let bot_response = bot_res.bot_response;
        // // console.log('bot_response',bot_responses.bot_response)
        //   // console.log('button response...',bot_responses.buttons_data)
        //   botResponse = { content: bot_responses.bot_response, sender: "bot", time: currentTime };
        //   setMessages(prevMessages => [...prevMessages, botResponse]);
        //   setIsTyping(false)
        // }, 1000);
      }

      //  const userMessage = { content: inputValue, sender: "user", time: currentTime };
      //  var user_Message = inputValue;
      //  console.log('user_Message.....',user_Message)
      //  // Add user message to messages state
      //  setMessages(prevMessages => [...prevMessages, userMessage]);
      //  ChatWithBot(user_Message)
      setInputValue(""); // Clear input field
      // setIsTyping(true);
    }
  };

  return (
    <div className="flex flex-col lg:h-screen  overflow-y-scroll scrollbar-thumb-rounded scrollbar-track-rounded-full scrollbar-thumb-gray-200 scrollbar-thin scrollbar-track-transparent ">
      {/* Navbar */}
      <nav className="bg-001d37 text-white p-4 lg:flex items-center">
        {/* Profile image and name */}
        <img
          src="/Group.png"
          alt="Profile"
          style={{ height: "30px", width: "auto" }}
          className="object-cover"
        />
        {/* Arrow icon */}
        {deviceType === "Mobile" && (
          <div className="ml-auto float-right">
            <FontAwesomeIcon
              onClick={openSideBarMenu}
              color="white"
              icon={openSideMenu ? faBarsStaggered : faBarsStaggered}
            />
          </div>
        )}
      </nav>
      {/* Main content */}
      <div className="lg:flex flex-1   ">
        {/* Sidebar (30% width) */}
        {deviceType === "Desktop" && <SidebarSection />}
        {openSideMenu ? (
          <MobileSidebarSection />
        ) : (
          <div className="flex-1 px-2 sm:px-6 lg:px-20 bg-[#f5f5f7] mb-4">
            {/* Chat box area */}
            <div className="  p-4 flex items-center mt-5 ">
              <input type="hidden" value={chatId} id="chatid" />
              <input type="hidden" value={chatModelId} id="chat_modelid" />
              <input type="hidden" value={chatName} id="chat_name" />
              <input type="hidden" value={chatEmail} id="chat_email" />
              <input type="hidden" value={chatPhone} id="chat_ph" />
              <input type="hidden" value={firstMessage} id="first_msg" />
              <input type="hidden" value={secondMessage} id="second_msg" />
              <input type="hidden" value={commMode} id="comm_mode" />

              <div className=" w-full  flex items-center border bg-white border-gray-300 rounded-lg focus-within:border-001d37 p-2 ">
                <input
                  type="text"
                  className="flex-1 py-2 px-3 border-none focus:outline-none"
                  placeholder="Type your message..."
                  value={inputValue}
                  onChange={(e) => handleInputChange(e)}
                  onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                />
                <button
                  tooltip="Send"
                  className="ml-2  text-white py-3 px-4 rounded-lg flex items-center justify-center"
                  style={{
                    background: "linear-gradient(to right, #C8A977, #BF8D3C)",
                  }}
                  onClick={handleSendMessage}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            </div>
            {isTyping && (
              <div className="p-4 flex items-center ">
                <FontAwesomeIcon
                  color="text-gray-500"
                  style={{ alignSelf: "end" }}
                  size="sm"
                  icon={faEllipsis}
                  bounce
                />
                <div id="typeTextLoader" className="text-gray-500"></div>
              </div>
            )}
            {/* Chat Section */}
            <div
              ref={scrollableRef}
              className="pb-12 overflow-y-auto max-h-[80vh] scrollbar-hide "
            >
              <div className="mx-auto  rounded-lg ">
                {/* Chat messages */}
                <div className="p-3 pb-12 mb-12 min-h-screen">
                  {messages
                    .slice(0)
                    .reverse()
                    .map((message, index) => (
                      // {messages.map((message, index) => (
                      <div
                        id={`messageData${index}`}
                        key={index}
                        className={`mb-2 flex sm:flex lg:none justify-start message-container`}
                      >
                        {/* Display user profile/avatar */}
                        {message.sender === "user" ? (
                          <div className="bg-001d37 w-9 h-9 min-w-9 min-h-9 rounded-full flex items-center justify-center font-helixa text-white mr-2 self-center">
                            you
                          </div>
                        ) : (
                          <div className="w-9 h-9 min-w-9 min-h-9 lg:w-9 lg:h-9 rounded-full flex items-center justify-center mr-2 font-helixa self-center bg-[#333222]">
                            <img
                              src="/new.png"
                              alt="Profile"
                              style={{ width: "25px", height: "auto" }}
                              className="  object-cover"
                            />
                          </div>
                        )}
                        {/* Display message */}
                        <div
                          className={` inline-block p-1 rounded-lg  text-sm text-black`}
                        >
                          {message.sender === "user" ? (
                            <p className="text-[#001d37] font-helixa font-semibold">
                              You {message.time}
                            </p>
                          ) : (
                            <p className="text-[#001d37] font-helixa font-semibold ">
                              Tlrlinus{" "}{message.time} 
                            </p>
                          )}
                          <div
                            className={`shadow-md p-4 mt-2 rounded-lg ${
                              message.sender === "user"
                                ? "bg-white"
                                : "bg-[#F8FCFF]"
                            }`}
                          >
                            <span className="flex-grow break-words font-helixa text-lg font-normal leading-[24.3px] tracking-[0.01em] text-left no-underline">
                              {message.content}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <button
                  onClick={() => scrollToTop(scrollableRef)}
                  className="fixed bottom-5 right-5 bg-gray-400 hover:bg-001d37 text-white font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out"
                >
                  <FontAwesomeIcon color="white" icon={faArrowUp} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
