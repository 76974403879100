import { useState } from "react";
import Expertise from "./expertiseSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faMessage } from "@fortawesome/free-solid-svg-icons";

function SidebarSection() {
  const [seeMore, setSeeMore] = useState(false);
  const [recentLists, setResentList] = useState([
    // {
    //   id: 1,
    //   title:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    // },
    // {
    //   id: 2,
    //   title:
    //     "Industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    // },
    // {
    //   id: 3,
    //   title:
    //     "When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    // },
    // {
    //   id: 4,
    //   title:
    //     " It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    // },
  ]);

  const [expertises, setExpertises] = useState([
    { id: 1, name: "Commercial" },
    { id: 2, name: "Corporate" },
    { id: 3, name: "Real Estate" },
    { id: 4, name: "Dispute Resolution" },
    { id: 5, name: "Corporate Restructuring" },
  ]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="w-1/5 bg-white text-sm p-4 font-semibold">
      <div className="px-[18px] mt-[6px]">
        {/* <div className=" py-3">
          <button className="flex w-full max-w-[280px] items-center bg-[#001D37] text-white py-2 px-4 rounded-lg hover:bg-[#003366] shadow-lg transition-all">
            <span className="text-lg font-semibold mr-2 font-helixa">+</span>
            <span className="text-lg font-semibold font-helixa">New Chat</span>
          </button>
        </div> */}
        <div className="mt-[30px]">
          <p className="text-[#0d0d0d] text-lg font-black leading-snug tracking-tight font-helixa  mb-[18px]">
            Recent Chats
          </p>
          {recentLists.map((list) => {
            return (
              <div key={list.id} className="mt-1">
                <button className="w-full flex items-center text-left text-gray-800 py-2 rounded-lg transition-all">
                  <span className="mr-3 text-inherit">
                    <img src="/Message.svg" alt="open in new" />
                  </span>
                  <span className="text-[#0d0d0d] text-lg font-normal leading-[27px] font-helixa">
                    {truncateText(list.title, 2)}
                  </span>
                </button>
              </div>
            );
          })}
        </div>
        <div className="my-[50px]">
          <p className="text-[#0d0d0d] text-lg font-black leading-snug tracking-tight font-helixa  mb-[18px]">
            Expertise
          </p>
          {expertises.map((list) => {
            return (
              <div key={list.id} className="mt-1">
                <button className="w-full flex items-center text-left text-gray-800 py-2 rounded-lg transition-all">
                  <span className="mr-3 text-inherit">
                    <img src="/open_in_new.svg" alt="open in new" />
                  </span>
                  <span className="text-[#0d0d0d] text-lg font-normal leading-[27px] font-helixa">
                    {list.name}
                  </span>
                </button>
              </div>
            );
          })}
        </div>
        <div>
          <p className="text-[#0d0d0d] text-lg font-black leading-snug tracking-tight font-helixa mb-[18px] ">
            About
          </p>
          <div className="mt-2">
            <span className="text-[#0d0d0d] text-lg font-normal leading-snug tracking-tight font-helixa">
              Chat With Tlrlinus is an advanced virtual assistant designed to
              simplify and streamline legal inquiries and tasks specifically
              within the framework{" "}
            </span>
            {!seeMore ? (
              <>
                {" "}
                <button
                  className="text-blue-500 hover:text-blue-700 text-[15px]"
                  onClick={() => {
                    setSeeMore(true);
                  }}
                >
                  . . .see more
                </button>
              </>
            ) : (
              <>
                {" "}
                <span className="text-[#0d0d0d] text-lg font-normal leading-snug tracking-tight font-helixa">
                  of UAE law. Whether you’re an individual, a business owner, or
                  a legal professional, Chat With Tlrlinus offers quick and
                  accurate assistance on a wide range of legal topics relevant
                  to the United Arab Emirates.{" "}
                </span>
                <button
                  className="text-blue-500 hover:text-blue-700 text-[15px]"
                  onClick={() => {
                    setSeeMore(false);
                  }}
                >
                 see less 
                </button>
              </>
            )}
          </div>
        </div>
        <div className="border-y-2 my-6 py-4 border-black">
          <p>
            The chatbot provides responses based on UAE laws and regulations
            only
          </p>
        </div>
        <div>
          <button className="w-full flex items-center text-left text-gray-800  rounded-lg transition-all">
            <span className="mr-3 text-inherit">
              <img src="/logout.svg" alt="Logout" />
            </span>
            <span className="truncate text-[18px] font-helixa">Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default SidebarSection;
