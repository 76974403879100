import axios from 'axios';


export const botCommunication = async (userMessage) => {
   console.log('usermesssssssssssssss', userMessage);
   // let RASA_API_URL = "http://localhost:5005/webhooks/rest/webhook";
   // let message = userMessage;
   // try {
   //     let result = await axios.post(RASA_API_URL, { message });
   //     console.log('sweeeee', result);
   // } catch (error) {
   //     console.error('Error communicating with the RASA API:', error);
   // }
   // RASA_API_URL = "http://localhost:5005/webhooks/rest/webhook"


   // rasa_response = requests.post(RASA_API_URL, json={'message': user_message})http://3.29.102.237:5000/ , https://api.tlrlinus.com/webhook
 
   try {
    console.log('hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
       let result = await axios.post('https://api.tlrlinus.com/webhook', {
     
           message: userMessage
       }).then(res=>{
            return res.data
         
       })
       console.log('flask ressssssult.......................')
       console.log('fffffffffffffffflask ressssssult.......................',result)
       
       let replacementWord = '';

        // Check if response['data'] ends with 'human'
        if (result['bot_response'].endsWith('Human')) {
            // Replace 'human' with the desired word
            result['bot_response'] = result['bot_response'].slice(0, -'Human'.length) + replacementWord;
        }else{
            result['bot_response'] = result['bot_response']
        }

       return result['bot_response']
     
   } catch (error) {
       console.error('Error communicating with flask API:', error);
   }
  
};

export const kycTotlr = async (userData) => {
   
    try {

        // let result = await axios.post('https://dev.tlr.ae/1/leads/add-enquiry/', {
        let result = await axios.post('https://nyk.tlrapi.com/leads/add-enquiry/', {
            name: userData.chatName,
            email: userData.chatEmail,
            mobile: userData.chatPhone, 
            address: 'address',
            remarks: userData.message,
            //matter_type:$("#matter_type").val(),
            stage: 'Active',
            referredBy: 'chatbot',
            orgid:'1',
            clientType: "Lead",
            digitalMarketing: 'chatbot',
        }).then(res=>{
            return res.status
           
        })
        console.log('tlr ressssssult',result)
        return result
       
    } catch (error) {
        console.error('Error communicating with TLRWEB API:', error);
    }
    
};

export const saveToChatModel = async (userData) => {
    console.log('inside save api')
    console.log(userData)
   
    try {
        
        let result = await axios.post(`https://stageapi.thelawreporters.com/chatbot/get-new-chatid/?userName=${userData.chatName}&userEmail=${userData.chatEmail}&mobile=${userData.chatPhone}&message=${userData.message}`)
        .then(res=>{
            return res.data
           
        })
        console.log('chat model res................',result)
        return result
       
    } catch (error) {
        console.error('Error communicating with save model API:', error);
    }
    
};

export const saveToChatHistory = async (userHistData) => {
    console.log('inside chat history api')
    console.log(userHistData)
    
   
    try {
        
        // https://stageapi.thelawreporters.com/let result = await axios.post(`http://127.0.0.1:8001/chatbot/post-chathistory/?chatid=${userHistData.chatid}&modelid=${userHistData.modelid}&question=${userHistData.question}&answer=${userHistData.answer}&infrm_status=${userHistData.infrm_status}`)
        let result = await axios.post(`https://stageapi.thelawreporters.com/chatbot/post-chathistory/?chatid=${userHistData.chatid}&modelid=${userHistData.modelid}&question=${userHistData.question}&answer=${userHistData.answer}&infrm_status=${userHistData.infrm_status}`)
        .then(res=>{
            return res.status
           
        })
        console.log('chat history res................',result)
        return result
       
    } catch (error) {
        console.error('Error communicating with chat history API:', error);
    }
    
};
