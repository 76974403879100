import { useState } from "react";


function Expertise() {
    
    return (<div className="">
        <p className="bg-001d37 p-2 mt-2 text-white flex hover:scale-110"><img src="./Corporate.png"/>Corporate</p>
        <p className="bg-001d37 p-2 mt-2 text-white flex hover:scale-110"><img src="./Commercial.png"/>Commercial</p>
        <p className="bg-001d37 p-2 mt-2 text-white flex hover:scale-110"><img src="./RealEstate.png"/>Real Estate</p>
        <p className="bg-001d37 p-2 mt-2 text-white flex hover:scale-110"><img src="./DisputeResolution.png"/>Dispute Resolution</p>
        <p className="bg-001d37 p-2 mt-2 text-white flex hover:scale-110"><img src="./CorporateRestructuring.png"/>Corporate Restructuring</p>


        <p className="mt-5 text-xs bottom-0">*The chatbot provides responses based on UAE laws and regulations only</p>

  </div>
     );
}

export default Expertise;