import { useState } from "react";
import Expertise from "./expertiseSection";


function SidebarSection() {
    let maxLength=100;
    const [isExpanded, setIsExpanded] = useState(false);
    // let text ='Sunil is a prominent corporate and commercial lawyer with nearly two decades of experience in leading international law firms and large diversified business conglomerates. Currently, he is the Principal Partner, Head of Office'
    let text = "TLR Linus is an advanced virtual assistant designed to simplify and streamline legal inquiries and tasks specifically within the framework of UAE law. Whether you’re an individual, a business owner, or a legal professional, TLR Linus offers quick and accurate assistance on a wide range of legal topics relevant to the United Arab Emirates."
    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };
    return (<div className="w-1/4 bg-white text-sm p-4 font-semibold">
    <p>"Within the tapestry of justice, ignorance of the law is no excuse. Every individual deserves unfettered access to accurate legal knowledge."</p>
    <div className="bg-gray-100 p-3  text-lg mt-4  bg-gradient-to-r from-001d37 ">
        <p className="text-white">About TLR Linus</p>
    </div>
    <div>
        {/* ===========================ABOUT SECTION================= */}
    <div className="pt-4">
      {text.length > maxLength && !isExpanded ? (
        <>
          <p className="text-gray-800">{`${text.slice(0, maxLength)}...`}</p>
          <button onClick={toggleExpansion} className="text-blue-400">..Read More</button>
        </>
      ) : (
        <>
          <p className="text-black">{text}</p>
          {text.length > maxLength && <button onClick={toggleExpansion}  className="text-blue-400">Read Less</button>}
        </>
      )}
    </div>

    {/* =================================================EXPERTISE=================================================== */}
    <div className="bg-gray-100 p-2  text-lg mt-4  bg-gradient-to-r from-001d37 mb-6">
        <p className="text-white">Expertise</p>
  
    </div>
    <Expertise/>
    </div>
  </div>
     );
}

export default SidebarSection;